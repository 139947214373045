// @mui material components
import { Grid, Card, Icon } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Import projects data
import Projects from "layouts/dashboard/components/AddressBook/components/Projects";
import { useMaterialUIController, setPageState } from "context";

function AddressBook() {
  const [, dispatch] = useMaterialUIController();
  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={10} md={8} lg={4}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Your Address Book
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Projects />
            </MDBox>
          </Card>
        </Grid>
        <Grid item xs={12} sm={10} md={8} lg={3}>
          <Card>
            <MDBox p={2}>
              <MDButton
                variant="contained"
                color="info"
                fullWidth
                onClick={() => {
                  setPageState(dispatch, "AddressPlus");
                }}
              >
                <Icon sx={{ mr: ".5rem" }}>add</Icon> Add New Address
              </MDButton>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default AddressBook;
