// Context imports
import { useMaterialUIController } from "context";

// Material Dashboard 2 React example components
import DashboardLayout from "./layout/DashboardLayout";
import DashboardNavbar from "./layout/DashboardNavbar";
import Footer from "./layout/Footer";

// Component Imports
import Store from "./components/Store";
import Contact from "./components/Contact";
import ContactSuccess from "./components/ContactSuccess";
import Checkout from "./components/Checkout";
import Customization from "./components/Customization";
import Orders from "./components/Orders";
import OrderPage from "./components/OrderPage";
import AddressBook from "./components/AddressBook";
import AddressPlus from "./components/AddressPlus";
import Shipping from "./components/Shipping";
import Review from "./components/Review";
import ThankYou from "./components/ThankYou";

function Dashboard() {
  const [controller] = useMaterialUIController();
  const { pageState, userStores } = controller;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <MDButton
        onClick={() => {
          setPageState(dispatch, "ContactSuccess");
        }}
      >
        Set Page State
      </MDButton> */}
      {[...userStores, "Dashboard"].includes(pageState) && <Store />}
      {pageState === "Contact" && <Contact />}
      {pageState === "ContactSuccess" && <ContactSuccess />}
      {pageState === "Checkout" && <Checkout />}
      {pageState === "Customization" && <Customization />}
      {pageState === "Orders" && <Orders />}
      {pageState === "OrderPage" && <OrderPage />}
      {pageState === "AddressBook" && <AddressBook />}
      {pageState === "AddressPlus" && <AddressPlus />}
      {pageState === "Shipping" && <Shipping />}
      {pageState === "Review" && <Review />}
      {pageState === "ThankYou" && <ThankYou />}
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
