// @mui imports
import { Card } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DataTable from "layouts/dashboard/components/AddressBook/components/DataTable";

// Data
import data from "layouts/dashboard/components/AddressBook/components/Projects/data";

function Projects() {
  const { columns, rows } = data();

  return (
    <Card>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
      </MDBox>
    </Card>
  );
}

export default Projects;
