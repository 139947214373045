/* eslint-disable no-restricted-syntax */
/* eslint-disable object-shorthand */

// react imports
import { useState } from "react";

// @mui material components
import { Grid, Divider } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// notistack imports
import { useSnackbar } from "notistack";

// Material Dashboard 2 React context
import { useMaterialUIController, setCart, setLocalCustomization, setPageState } from "context";

function CustomizationForm() {
  const [controller, dispatch] = useMaterialUIController();
  const { cart, localCustomization, editOrder } = controller;
  const { enqueueSnackbar } = useSnackbar();

  const [proceedToShipping, setProceedToShipping] = useState(false);

  const [clinicName, setClinicName] = useState(localCustomization.clinicName);
  const [line2, setLine2] = useState(localCustomization.line2);
  const [line3, setLine3] = useState(localCustomization.line3);

  const [clinicNameError, setClinicNameError] = useState(false);

  const canCustomizeArray = cart.filter((customizedItem) => customizedItem.willCustomize === true);

  const customizationRequired = cart.map((x) => x.customizationRequired).includes(true);

  const saveCustomization = () => {
    if (clinicName.length === 0) {
      setClinicNameError(true);
    } else {
      setClinicNameError(false);
    }

    if (clinicName.length !== 0) {
      setClinicNameError(false);
      setProceedToShipping(true);
      const updatedCartItem = cart.map((x) =>
        x.willCustomize === true
          ? {
              ...x,
              customizationItems: [
                { title: "Clinic/Company", item: clinicName },
                { title: "Line 2", item: line2 },
                { title: "Line 3", item: line3 },
              ],
            }
          : x
      );
      const newCustomization = {
        customized: true,
        clinicName: clinicName,
        line2: line2,
        line3: line3,
      };
      setLocalCustomization(dispatch, newCustomization);
      window.localStorage.setItem("customization", JSON.stringify(newCustomization));
      setCart(dispatch, updatedCartItem);
      window.localStorage.setItem("cart", JSON.stringify(updatedCartItem));
      const variant = "success";
      enqueueSnackbar("Your customization has been saved!", {
        variant,
        autoHideDuration: 2000,
      });
      console.log(cart);
      setPageState(dispatch, "Shipping");
    }
  };

  const removeCustomization = () => {
    setClinicNameError(false);
    setProceedToShipping(true);
    const updatedCartItem = cart.map((x) =>
      x.willCustomize === true ? { ...x, willCustomize: false, customizationOptions: [] } : x
    );
    setCart(dispatch, updatedCartItem);
    window.localStorage.setItem("cart", JSON.stringify(updatedCartItem));
    const blankCustomization = { customized: false, clinicName: "", line2: "", line3: "" };
    window.localStorage.setItem("customization", JSON.stringify(blankCustomization));
    const variant = "warning";
    enqueueSnackbar("Your customization has been removed.", {
      variant,
      autoHideDuration: 2000,
    });
    console.log(cart);
    setPageState(dispatch, "Shipping");
  };

  return (
    <MDBox p={2}>
      <Grid container spacing={2}>
        {canCustomizeArray.map((item) => (
          <Grid item key={item.newID} xs={6} sm={4}>
            <MDBox
              component="img"
              src={item.image}
              alt={item.newID}
              borderRadius="lg"
              shadow="md"
              width="100%"
              height="100%"
              position="relative"
              zIndex={1}
            />
          </Grid>
        ))}
      </Grid>
      <MDBox py={2}>
        {clinicNameError && (
          <MDTypography variant="body2" color="error">
            Please enter a clinic name.
          </MDTypography>
        )}
        <MDInput
          type="text"
          label="Clinic/Company Name"
          value={clinicName}
          inputProps={{ maxLength: 35 }}
          style={{ width: "100%" }}
          onChange={(e) => {
            setClinicName(e.target.value);
          }}
        />
      </MDBox>
      <MDBox py={2}>
        <MDInput
          type="text"
          label="2nd Line"
          value={line2}
          inputProps={{ maxLength: 35 }}
          style={{ width: "100%" }}
          onChange={(e) => {
            setLine2(e.target.value);
          }}
        />
      </MDBox>
      <MDBox py={2}>
        <MDInput
          type="text"
          label="3rd Line"
          value={line3}
          inputProps={{ maxLength: 35 }}
          style={{ width: "100%" }}
          onChange={(e) => {
            setLine3(e.target.value);
          }}
        />
      </MDBox>
      <MDBox pt={2}>
        <MDButton
          variant={proceedToShipping ? "contained" : "outlined"}
          color={proceedToShipping ? "success" : "info"}
          sx={{ width: "100%" }}
          onClick={saveCustomization}
        >
          {proceedToShipping ? "Proceed to Shipping" : "Save Customization"}
        </MDButton>
        {!customizationRequired && (
          <MDButton
            variant="text"
            color="secondary"
            sx={{ width: "100%", marginTop: ".5rem" }}
            onClick={removeCustomization}
          >
            nevermind, I don&apos;t want to customize these items
          </MDButton>
        )}
      </MDBox>
      <Divider />
      {editOrder && (
        <MDBox py={2}>
          <MDButton
            color="dark"
            onClick={() => {
              setPageState(dispatch, "Review");
            }}
            fullWidth
          >
            return to order review
          </MDButton>
        </MDBox>
      )}
    </MDBox>
  );
}

export default CustomizationForm;
