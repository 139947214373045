// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import QDCartAvatar from "components/QDCartAvatar";

function CartCard({ id, image, title, cartQuantity = 0, language, willCustomize, remove }) {
  return (
    <MDBox mt={1} mb={2} id={id}>
      <Grid container spacing={3} alignItems="center">
        <Grid item>
          <Icon fontSize="small" color="inherit" onClick={remove} sx={{ cursor: "pointer" }}>
            delete
          </Icon>
        </Grid>
        <Grid item>
          <QDCartAvatar src={image} alt="profile-image" variant="square" size="xl" shadow="sm" />
        </Grid>
        <Grid item>
          <MDBox height="100%" mt={0.5} lineHeight={1}>
            <MDTypography variant="h6" fontWeight="medium">
              {title.slice(0, 15)}
              {title.length > 15 && "..."}
            </MDTypography>
            {willCustomize && (
              <MDTypography variant="caption" color="success" fontWeight="medium">
                *customization
              </MDTypography>
            )}
            <MDTypography
              variant="button"
              color="text"
              fontWeight="regular"
              sx={{ display: "block" }}
            >
              Language: {language}
            </MDTypography>
            <MDTypography
              variant="button"
              color="text"
              fontWeight="regular"
              sx={{ display: "block" }}
            >
              Quantity: {cartQuantity}
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

// Typechecking props for the CartCard
CartCard.propTypes = {
  id: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cartQuantity: PropTypes.number.isRequired,
  willCustomize: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
};

export default CartCard;
