/* eslint-disable no-unused-expressions */

// @mui material components
import { Grid, Card, Divider } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Context imports
import { useMaterialUIController, setPageState } from "context";

function ContactSuccess() {
  const [controller, dispatch] = useMaterialUIController();
  const { userStores } = controller;

  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={10} md={6}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Message Sent
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <MDBox p={2}>
                <MDBox p={2}>
                  <MDTypography variant="h4">Your message has been sent.</MDTypography>
                  <MDTypography variant="body2">
                    A member of our support team will respond to your message within 2 business
                    days.
                  </MDTypography>
                  <Divider />
                  <MDButton
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setPageState(dispatch, userStores[0]);
                    }}
                    fullWidth
                  >
                    Return to Store
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default ContactSuccess;
