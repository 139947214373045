/* eslint-disable no-restricted-syntax */
/* eslint-disable object-shorthand */

// react imports
import { useState } from "react";

// Material UI imports
import { Divider } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// notistack imports
import { useSnackbar } from "notistack";

// Context imports
import { useMaterialUIController, setPageState } from "context";

// Firebase imports
import { getAuth } from "firebase/auth";

function ContactForm() {
  const [controller, dispatch] = useMaterialUIController();
  const { displayName } = controller;

  const { enqueueSnackbar } = useSnackbar();

  const userEmail = getAuth().currentUser.email;

  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState(false);
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(false);

  const sendMessage = () => {
    if (subject.length === 0) {
      setSubjectError(true);
    }

    if (message.length === 0) {
      setMessageError(true);
    }

    if (subject.length !== 0 && message.length !== 0) {
      const sendmailPath =
        "https://69r6av39qj.execute-api.us-east-1.amazonaws.com/default/storefront-sendmail";
      const body = JSON.stringify({
        senderName: displayName,
        senderEmail: userEmail,
        senderSubject: subject,
        senderMessage: message,
      });

      const requestOptions = {
        method: "post",
        body,
      };

      fetch(sendmailPath, requestOptions)
        .then((response) => {
          if (!response.ok) throw new Error("Error in fetch");
          return response.json();
        })
        .then((response) => {
          console.log(response);
          console.log("Sent to Lambda successfully!");
        })
        .catch((error) => {
          console.log(error);
          console.log("Email didn't work!");
        });

      const variant = "success";
      enqueueSnackbar(`Your message has been sent!`, {
        variant,
        autoHideDuration: 2000,
      });
      setPageState(dispatch, "ContactSuccess");
    }
  };

  return (
    <MDBox p={2}>
      <MDBox pb={2} px={2}>
        <MDTypography variant="body2">
          You were working away, eager to submit a storefront order. And then… oof! Something
          happened – a bad link, a surprising error message, or the site crashed on you. Or maybe
          you realized you accidently made an error in your order and it needs to be changed. Never
          fear, we’re here to help with all technical and order issues. Please fill out the info
          below and submit.{" "}
          <u>
            IMPORTANT: In your message, please tell us which study’s Storefront (study
            name/sponsor/indication) needs to be addressed, AND what the issue is.
          </u>{" "}
          Depending on the issue, someone from the Storefront team should be back in touch with you
          within two business days.
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDInput type="text" label="Name" value={displayName} style={{ width: "100%" }} disabled />
      </MDBox>
      <MDBox p={2}>
        <MDInput type="text" label="Email" value={userEmail} style={{ width: "100%" }} disabled />
      </MDBox>
      <MDBox p={2}>
        {subjectError && (
          <MDTypography variant="caption" color="error">
            Please enter a subject line.
          </MDTypography>
        )}
        <MDInput
          type="text"
          label="Subject"
          value={subject}
          onChange={(e) => {
            setSubject(e.target.value);
          }}
          style={{ width: "100%" }}
          required
        />
      </MDBox>
      <MDBox p={2}>
        {messageError && (
          <MDTypography variant="caption" color="error">
            Please enter a message.
          </MDTypography>
        )}
        <MDInput
          type="textarea"
          label="Message"
          value={message}
          multiline
          rows={10}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          style={{ width: "100%" }}
          required
        />
      </MDBox>
      <Divider />
      <MDBox p={2}>
        <MDButton
          variant="outlined"
          color="info"
          onClick={() => {
            sendMessage();
          }}
          fullWidth
        >
          Send Message
        </MDButton>
      </MDBox>
    </MDBox>
  );
}

export default ContactForm;
