import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBMTdScuJfc-3ZLkPiTKG6n-ZoXxYnOeEM",
  authDomain: "qd-storefront.firebaseapp.com",
  projectId: "qd-storefront",
  storageBucket: "qd-storefront.appspot.com",
  messagingSenderId: "632451182828",
  appId: "1:632451182828:web:be74cd889f018d6a1f75c9",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// Authentication functions
const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    window.location.pathname = "/";
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  window.localStorage.clear();
  signOut(auth);
};

export { auth, db, logInWithEmailAndPassword, logout };
