// @mui imports
import { Card } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layouts/dashboard/components/Orders/components/Projects/data";

function Projects() {
  const { columns, rows } = data();

  return (
    <Card>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted
          noEndBorder
          entriesPerPage
          canSearch
        />
      </MDBox>
    </Card>
  );
}

export default Projects;
