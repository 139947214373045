/* eslint-disable no-restricted-syntax */

// react imports
import { useState, useEffect } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// notistack imports
import { useSnackbar } from "notistack";

// @mui material components
import { Divider, Card, NativeSelect, Icon, Grid } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import QDCartAvatar from "components/QDCartAvatar";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setCart,
  setEmptyCart,
  setCheckoutEdit,
  setChosenAddress,
  setFinalAddress,
  setLocalCustomization,
  setPageState,
} from "context";

function CheckoutCart({ header, profiles, shadow }) {
  const [controller, dispatch] = useMaterialUIController();
  const { cart, cartStudies, emptyCart, editOrder, checkoutEdit } = controller;

  const [updatedCart, setUpdatedCart] = useState(cart);

  const { enqueueSnackbar } = useSnackbar();

  const [cartCustomizations, setCartCustomizations] = useState(false);

  useEffect(() => {
    const hasCustomizedItems = cart.find((item) => item.willCustomize === true);
    setCartCustomizations(hasCustomizedItems);
  }, [cart]);

  const removeFromCart = (el) => {
    let hardCopy = [...cart];
    hardCopy = hardCopy.filter((cartItem) => cartItem.newID !== el.newID);
    const localStorageCopy = JSON.stringify(hardCopy);
    setCart(dispatch, hardCopy);
    setUpdatedCart(hardCopy);
    window.localStorage.setItem("cart", localStorageCopy);
    const variant = "error";
    enqueueSnackbar(`${el.title} - ${el.language} has been removed from the cart.`, {
      variant,
      autoHideDuration: 2000,
    });
    const cartLength = JSON.parse(window.localStorage.getItem("cart")).length;
    if (cartLength === 0) setEmptyCart(dispatch, true);
  };

  const setNewCartValue = (el) => {
    const updatedCartItem = cart.map((x) =>
      x.newID === el.newID
        ? { ...x, cartQuantity: Number(el.cartQuantity), language: el.language }
        : x
    );

    setUpdatedCart(updatedCartItem);
  };

  const saveNewCart = async () => {
    await setCart(dispatch, updatedCart);

    setCheckoutEdit(dispatch, false);

    const variant = "success";
    enqueueSnackbar(`Your cart edits have been saved!`, {
      variant,
      autoHideDuration: 2000,
    });
  };

  const setCartEmpty = () => {
    setCart(dispatch, []);
    window.localStorage.removeItem("cart");
    setEmptyCart(dispatch, true);
    setChosenAddress(dispatch, {
      nickName: "",
      attn: "",
      clinic: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: "",
      state: "",
      zip: "",
      country: "United States",
    });
    setFinalAddress(dispatch, {
      attn: "",
      clinic: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: "",
      state: "",
      zip: "",
      country: "United States",
    });
    const blankCustomizations = { customized: false, clinicName: "", line2: "", line3: "" };
    setLocalCustomization(dispatch, blankCustomizations);
    window.localStorage.setItem("customization", JSON.stringify(blankCustomizations));
    const variant = "success";
    enqueueSnackbar(`Cart has been emptied.`, {
      variant,
      autoHideDuration: 2000,
    });
  };

  const renderProfiles = Array.from(cartStudies).map((study) => (
    <Grid item xs={12}>
      <MDBox
        py={1.5}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          {study}
        </MDTypography>
      </MDBox>
      <Grid container mt={2}>
        {profiles
          .filter((item) => item.study === study)
          .map(
            ({
              id,
              newID,
              image,
              title,
              cartQuantity,
              language,
              languageVersion,
              quantityOptions,
              willCustomize,
            }) => (
              <Grid key={newID} item xs={12} sm={6}>
                <MDBox component="li" display="flex" alignItems="center" py={1} mb={1}>
                  <MDBox mr={2}>
                    <QDCartAvatar src={image} alt="something here" shadow="md" variant="square" />
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                  >
                    <MDTypography variant="button" fontWeight="medium">
                      {title}
                    </MDTypography>
                    {willCustomize && (
                      <MDTypography variant="caption" color="success" fontWeight="medium">
                        *customized
                      </MDTypography>
                    )}
                    <MDTypography variant="caption" color="text">
                      Language:
                      {!checkoutEdit && cart.filter((e) => e.id === id).length === 1 && language}
                      {cart.filter((e) => e.id === id).length > 1
                        ? language
                        : checkoutEdit && (
                            <NativeSelect
                              onChange={(e) => {
                                setNewCartValue({
                                  id,
                                  newID,
                                  language: e.target.value,
                                  cartQuantity,
                                });
                                console.log(e.target.value);
                              }}
                              defaultValue={language}
                              inputProps={{
                                name: "quantity",
                                id: "uncontrolled-native",
                              }}
                            >
                              {languageVersion.map((x) => (
                                <option value={x}>{x}</option>
                              ))}
                            </NativeSelect>
                          )}
                    </MDTypography>
                    <MDTypography variant="caption" color="text">
                      Quantity:{" "}
                      {checkoutEdit ? (
                        <NativeSelect
                          onChange={(e) => {
                            let quantity = e.target.value;
                            if (!e.target.value) quantity = 0;
                            setNewCartValue({ id, newID, cartQuantity: quantity, language });
                            console.log(e.target.value);
                          }}
                          defaultValue={cartQuantity}
                          inputProps={{
                            name: "quantity",
                            id: "uncontrolled-native",
                          }}
                        >
                          {quantityOptions.map((x) => (
                            <option value={x}>{x}</option>
                          ))}
                        </NativeSelect>
                      ) : (
                        cartQuantity
                      )}
                      {checkoutEdit ? (
                        <MDButton
                          component="button"
                          onClick={() => {
                            removeFromCart({ newID, title, language });
                          }}
                          variant="text"
                          color="info"
                        >
                          <Icon>delete</Icon>
                        </MDButton>
                      ) : (
                        ""
                      )}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Grid>
            )
          )}
      </Grid>
    </Grid>
  ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {header}
        </MDTypography>
        {emptyCart && <MDTypography variant="caption">Your cart is empty!</MDTypography>}
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <Grid container spacing={3}>
            {renderProfiles}
          </Grid>
          {cartCustomizations && (
            <MDBox p={1}>
              <MDTypography variant="caption" color="success">
                Your cart has customizable items in it.
              </MDTypography>
            </MDBox>
          )}
          <MDBox p={2}>
            <MDButton
              color="text"
              variant="outlined"
              size="small"
              fullWidth
              disabled={emptyCart}
              onClick={setCartEmpty}
            >
              <Icon sx={{ mx: 1 }}>delete</Icon> empty cart
            </MDButton>
          </MDBox>
          <Divider />
        </MDBox>
        <MDBox p={2}>
          {checkoutEdit ? (
            <MDBox>
              <MDBox py={2}>
                <MDButton
                  color="info"
                  variant="text"
                  size="small"
                  onClick={() => {
                    setCheckoutEdit(dispatch, false);
                  }}
                  disabled={emptyCart}
                  fullWidth
                >
                  cancel
                </MDButton>
              </MDBox>
              <MDBox py={2}>
                <MDButton
                  color="info"
                  variant="contained"
                  onClick={() => {
                    saveNewCart();
                    setCheckoutEdit(dispatch, false);
                  }}
                  fullWidth
                >
                  save order
                </MDButton>
              </MDBox>
            </MDBox>
          ) : (
            <MDBox py={2}>
              <MDButton
                color="info"
                variant="outlined"
                onClick={() => {
                  setCheckoutEdit(dispatch, true);
                }}
                disabled={emptyCart}
                fullWidth
              >
                edit order
              </MDButton>
            </MDBox>
          )}
          {cartCustomizations ? (
            <MDButton
              color="info"
              onClick={() => {
                setPageState(dispatch, "Customization");
              }}
              fullWidth
            >
              {editOrder ? "edit customization" : "continue to customization"}
            </MDButton>
          ) : (
            <MDBox py={2}>
              <MDButton
                color="info"
                onClick={() => {
                  setPageState(dispatch, "Shipping");
                }}
                disabled={emptyCart}
                fullWidth
              >
                continue to shipping
              </MDButton>
            </MDBox>
          )}
          {editOrder && (
            <MDBox py={2}>
              <MDButton
                color="dark"
                onClick={() => {
                  setPageState(dispatch, "Review");
                }}
                fullWidth
              >
                return to order review
              </MDButton>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the CheckoutCart
CheckoutCart.defaultProps = {
  shadow: true,
};

// Typechecking props for the CheckoutCart
CheckoutCart.propTypes = {
  header: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
};

export default CheckoutCart;
