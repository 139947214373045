/* eslint-disable no-restricted-syntax */

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Card, Grid } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import QDCartAvatar from "components/QDCartAvatar";
import FinalAddress from "layouts/dashboard/components/Review/components/FinalAddress";

// Context imports
import { useMaterialUIController } from "context";

function ReviewList({ header, profiles, shadow }) {
  const [controller] = useMaterialUIController();
  const { cartStudies } = controller;

  const renderProfiles = Array.from(cartStudies).map((study) => (
    <Grid item xs={12}>
      <MDBox
        py={1.5}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          {study}
        </MDTypography>
      </MDBox>
      <Grid container mt={2}>
        {profiles
          .filter((item) => item.study === study)
          .map(({ newID, image, title, cartQuantity, language, willCustomize }) => (
            <Grid key={newID} item xs={12} sm={3} md={4}>
              <MDBox component="li" display="flex" alignItems="center" py={1} mb={1}>
                <MDBox mr={2}>
                  <QDCartAvatar src={image} alt="something here" shadow="md" variant="square" />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="center"
                >
                  <MDTypography variant="button" fontWeight="medium">
                    {title}
                  </MDTypography>
                  {willCustomize && (
                    <MDTypography variant="caption" color="success" fontWeight="medium">
                      *customized
                    </MDTypography>
                  )}
                  <MDTypography variant="caption" color="text">
                    Language: {language}
                  </MDTypography>
                  <MDTypography variant="caption" color="text">
                    Quantity: {cartQuantity || "0"}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
          ))}
      </Grid>
    </Grid>
  ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
          sx={{ marginBottom: "1rem" }}
        >
          {header}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <Grid container spacing={3} sx={{ marginBottom: "1rem" }}>
          {renderProfiles}
        </Grid>
        <FinalAddress />
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfilesList
ReviewList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
ReviewList.propTypes = {
  header: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
};

export default ReviewList;
