// react imports
import { useEffect } from "react";

// @mui material components
import { Grid, Card, Icon, Divider } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import TimelineItem from "layouts/dashboard/components/OrderPage/components/Timeline/TimelineItem";

// Material Dashboard 2 React context
import { useMaterialUIController, setActiveNavLink, setPageState } from "context";

function OrderPage() {
  const [controller, dispatch] = useMaterialUIController();
  const { orderpage, orderpageEmpty } = controller;

  const itemArray = orderpage.items.map((x) => [x.image, x.title]);
  const itemListArray = orderpage.items.map((x) => [
    x.title,
    x.title,
    x.cartQuantity,
    x.language,
    x.willCustomize,
  ]);
  const customizationsArray = orderpage.items.find((obj) => obj.willCustomize === true);
  const timelineArray = orderpage.statusTimeline.map((x) => [
    x.id,
    x.statusTitle,
    x.statusDetails,
    x.statusColor,
    x.statusIcon,
    !orderpageEmpty && x.timeStamp.toDate().toLocaleDateString(),
    x.comment.title,
    x.comment.details,
    x.isLast,
  ]);
  // const orderpageArray = orderpage.items.map((x) => [x.title, x.quantity]);

  useEffect(() => {
    setActiveNavLink(dispatch, "orders");
  }, []);

  const avatars = (members) =>
    members.map(([image, key]) => (
      <Grid item key={key} xs={4}>
        {/* <MDAvatar src={image} alt="name" size="lg" variant="square" />
         */}
        <MDBox
          component="img"
          src={image}
          alt={key}
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="relative"
          zIndex={1}
        />
      </Grid>
    ));

  const itemBreakdown = (list) =>
    list.map(([key, title, quantity, language, customized]) => (
      <Grid item>
        <MDTypography key={key} variant="body1" sx={{ fontSize: ".75rem" }}>
          <strong>{title}:</strong>
          <br />
          Quantity: {quantity || "0"}
          <br />
          Version: {language}
          <br />
          Customized: {customized ? <p style={{ color: "green", display: "inline" }}>Yes</p> : "No"}
          <br />
          --
        </MDTypography>
      </Grid>
    ));

  const timelineMap = (item) =>
    item.map(
      ([
        id,
        statusTitle,
        statusDetails,
        statusColor,
        statusIcon,
        date,
        commentTitle,
        commentDetails,
        isLast,
      ]) => (
        <TimelineItem
          key={id}
          color={statusColor}
          icon={statusIcon}
          title={statusTitle}
          description={statusDetails}
          dateTime={date}
          commentTitle={commentTitle}
          commentDetails={commentDetails}
          lastItem={isLast}
        />
      )
    );

  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6}>
          <Card>
            {orderpageEmpty ? (
              <>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    No Order Chosen
                  </MDTypography>
                </MDBox>
                <Card sx={{ height: "100%", boxShadow: "none" }}>
                  <MDBox pb={2} px={2}>
                    <MDBox py={2}>
                      <MDButton
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={() => {
                          setPageState(dispatch, "Orders");
                        }}
                      >
                        <Icon>arrow_back_ios</Icon>
                      </MDButton>
                    </MDBox>
                    <MDTypography variant="h6">You haven&apos;t chosen an order</MDTypography>
                    <MDTypography variant="body2" sx={{ fontSize: ".75rem" }}>
                      Return to the orders page.
                    </MDTypography>
                    <Divider />
                  </MDBox>
                </Card>
              </>
            ) : (
              <>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Order {orderpage.orderId}
                  </MDTypography>
                </MDBox>
                <Card sx={{ height: "100%", boxShadow: "none" }}>
                  <MDBox py={2} px={2}>
                    <MDBox py={2}>
                      <MDButton
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={() => {
                          setPageState(dispatch, "Orders");
                        }}
                      >
                        <Icon>arrow_back_ios</Icon>
                      </MDButton>
                    </MDBox>
                    <Grid container spacing={3}>
                      {avatars(itemArray)}
                    </Grid>
                  </MDBox>
                  <MDBox pb={2} px={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <MDBox py={2} px={2} borderRadius={5} bgColor="light">
                          <MDTypography variant="h6">Shipping Information</MDTypography>
                          <MDTypography variant="body2" sx={{ fontSize: ".75rem" }}>
                            {orderpage.shippingAddress.email}
                            <br />
                            {orderpage.shippingAddress.phone}
                            <br />
                            <Divider sx={{ margin: ".2rem" }} />
                            <strong>Attention: </strong>
                            {orderpage.shippingAddress.attn}
                            <br />
                            {orderpage.shippingAddress.addressLine1}
                            <br />
                            {orderpage.shippingAddress.addressLine2.length === 0 ? (
                              ""
                            ) : (
                              <>
                                {orderpage.shippingAddress.addressLine2} <br />
                              </>
                            )}
                            {orderpage.shippingAddress.addressLine3.length === 0 ? (
                              ""
                            ) : (
                              <>
                                {orderpage.shippingAddress.addressLine3} <br />
                              </>
                            )}
                            {orderpage.shippingAddress.city}, {orderpage.shippingAddress.state}
                            <br />
                            {orderpage.shippingAddress.zip}
                            <br />
                            {orderpage.shippingAddress.country}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MDBox py={2} px={2} borderRadius={5}>
                          <MDTypography variant="h6">Customization</MDTypography>
                          {customizationsArray === undefined ? (
                            <MDTypography variant="body2" sx={{ fontSize: ".75rem" }}>
                              No Customization
                            </MDTypography>
                          ) : (
                            customizationsArray.customizationItems.map((item) => (
                              <MDTypography
                                key={item.newID}
                                variant="body2"
                                sx={{ fontSize: ".75rem" }}
                              >
                                {item.title}: {item.item}
                                <br />
                              </MDTypography>
                            ))
                          )}
                        </MDBox>
                      </Grid>
                    </Grid>
                    <MDBox py={2} px={2} borderRadius={5}>
                      <MDTypography variant="h6">Order Summary</MDTypography>
                      <Grid container spacing={3}>
                        {itemBreakdown(itemListArray)}
                      </Grid>
                    </MDBox>
                    <MDBox py={2} px={2} borderRadius={5} bgColor="light">
                      <MDTypography variant="h6">Order Timeline</MDTypography>
                      <MDBox p={2}>{timelineMap(timelineArray)}</MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default OrderPage;
