/* eslint-disable no-restricted-syntax */

// react imports
import { useState, useEffect } from "react";

// @mui material components
import { Divider, Radio } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// notistack imports
import { useSnackbar } from "notistack";

// Material Dashboard 2 React context
import { useMaterialUIController, setFinalAddress, setPageState } from "context";

function CheckoutAddress() {
  const [controller, dispatch] = useMaterialUIController();
  const { cart, chosenAddress, editOrder } = controller;
  const { enqueueSnackbar } = useSnackbar();

  const [country, setCountry] = useState(chosenAddress.country);
  const [countryError, setCountryError] = useState(false);
  const [isInternational, setIsInternational] = useState(chosenAddress.country !== "United States");
  const [email, setEmail] = useState(chosenAddress.email);
  const [emailError, setEmailError] = useState(false);
  const [phone, setPhone] = useState(chosenAddress.phone);
  const [phoneError, setPhoneError] = useState(false);
  const [attn, setAttn] = useState(chosenAddress.attn);
  const [attnError, setAttnError] = useState(false);
  const [clinic, setClinic] = useState(chosenAddress.clinic);
  const [clinicError, setClinicError] = useState(false);
  const [addressLine1, setAddressLine1] = useState(chosenAddress.addressLine1);
  const [addressLine1Error, setAddressLine1Error] = useState(false);
  const [addressLine2, setAddressLine2] = useState(chosenAddress.addressLine2);
  // const [addressLine2Error, setAddressLine2Error] = useState(false);
  const [addressLine3, setAddressLine3] = useState(chosenAddress.addressLine3);
  // const [addressLine3Error, setAddressLine3Error] = useState(false);
  const [city, setCity] = useState(chosenAddress.city);
  const [cityError, setCityError] = useState(false);
  const [state, setState] = useState(chosenAddress.state);
  const [stateError, setStateError] = useState(false);
  const [zip, setZip] = useState(chosenAddress.zip);
  const [zipError, setZipError] = useState(false);
  const [cartError, setCartError] = useState(false);

  const [shippingSaved, setShippingSaved] = useState(false);

  useEffect(() => {
    console.log(country);
  }, [country]);

  const checkShipping = async (el) => {
    console.log(el);

    if (country === undefined) {
      return;
    }

    if (attn === "") {
      setAttnError(true);
    }

    if (email === "") {
      setEmailError(true);
    }

    if (phone === "") {
      setPhoneError(true);
    }

    if (clinic === "") {
      setClinicError(true);
    }

    if (addressLine1 === "") {
      setAddressLine1Error(true);
    }

    if (city === "") {
      setCityError(true);
    }

    if (state === "") {
      setStateError(true);
    }

    if (zip === "") {
      setZipError(true);
    }

    if (country === "") {
      setCountryError(true);
    }

    if (cart.length === 0) {
      setCartError(true);
    }

    if (addressLine1 && city && zip && cart.length !== 0) {
      // const today = new Date();
      // const orderID = uuid();

      const itemsArray = [];
      cart.map((object) =>
        itemsArray.push({
          image: object.image,
          title: object.title,
          quantity: object.cartQuantity || 0,
          language: object.language,
          customized: object.isCustomized,
        })
      );

      const shippingAddress = {
        email: el.email,
        phone: el.phone,
        attn: el.attn,
        country: el.country,
        clinic: el.clinic,
        addressLine1: el.addressLine1,
        addressLine2: el.addressLine2,
        addressLine3: el.addressLine3,
        city: el.city,
        state: el.state,
        zip: el.zip,
      };

      setFinalAddress(dispatch, shippingAddress);
      window.localStorage.setItem("chosenAddress", JSON.stringify(shippingAddress));

      const variant = "success";
      enqueueSnackbar("Shipping address added to order!", {
        variant,
        autoHideDuration: 2000,
      });
      setShippingSaved(true);
    } else {
      console.log("fill in that info!");
    }
  };

  return (
    <MDBox p={2}>
      <MDBox p={2}>
        <MDButton
          variant="contained"
          color="info"
          sx={{ mb: "1rem" }}
          onClick={() => {
            setPageState(dispatch, "AddressBook");
          }}
          fullWidth
        >
          add address from address book
        </MDButton>
      </MDBox>
      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
        shipping info
      </MDTypography>
      <MDBox p={2}>
        {attnError && (
          <MDTypography variant="caption" color="error">
            Please enter an ATTN to.
          </MDTypography>
        )}
        <MDInput
          type="text"
          label="Attention to"
          value={attn}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            setAttn(e.target.value);
          }}
          style={{ width: "100%" }}
        />
      </MDBox>
      <MDBox p={2}>
        {emailError && (
          <MDTypography variant="caption" color="error">
            Please enter an email address.
          </MDTypography>
        )}
        <MDInput
          type="text"
          label="Email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          style={{ width: "100%" }}
        />
      </MDBox>
      <MDBox p={2}>
        {phoneError && (
          <MDTypography variant="caption" color="error">
            Please enter a phone number.
          </MDTypography>
        )}
        <MDInput
          type="text"
          label="Phone #"
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
          style={{ width: "100%" }}
        />
      </MDBox>
      <MDBox p={2}>
        <MDTypography variant="caption">Select Country:</MDTypography>
        <Radio
          checked={country === "United States"}
          onChange={(e) => {
            setCountry(e.target.value);
            setCountryError(false);
            setIsInternational(false);
          }}
          value="United States"
          name="radio-buttons"
          inputProps={{ "aria-label": "United States" }}
        />
        <MDTypography variant="caption">United States</MDTypography>
        <Radio
          checked={country !== "United States"}
          onChange={() => {
            setCountry("");
            setIsInternational(true);
          }}
          value="International"
          name="radio-buttons"
          inputProps={{ "aria-label": "International" }}
        />
        <MDTypography variant="caption">International</MDTypography>
      </MDBox>
      <MDBox p={2}>
        {countryError && (
          <MDTypography variant="caption" color="error">
            Please enter a Country.
          </MDTypography>
        )}
        {isInternational && (
          <MDInput
            type="text"
            label="Country Name"
            value={country}
            inputProps={{ maxLength: 35 }}
            onChange={(e) => {
              setCountry(e.target.value);
            }}
            style={{ width: "100%" }}
          />
        )}
      </MDBox>
      <MDBox p={2}>
        {clinicError && (
          <MDTypography variant="caption" color="error">
            Please enter a clinic name.
          </MDTypography>
        )}
        <MDInput
          type="text"
          label="Clinic Name"
          value={clinic}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            setClinic(e.target.value);
          }}
          style={{ width: "100%" }}
        />
      </MDBox>
      <MDBox p={2}>
        {addressLine1Error && (
          <MDTypography variant="caption" color="error">
            Please enter an address.
          </MDTypography>
        )}
        <MDInput
          type="text"
          label="Address Line 1"
          value={addressLine1}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            setAddressLine1(e.target.value);
          }}
          style={{ width: "100%" }}
        />
      </MDBox>
      <MDBox p={2}>
        {/* {addressLine2Error && (
              <MDTypography variant="caption" color="error">
                Please enter an address.
              </MDTypography>
            )} */}
        <MDInput
          type="text"
          label="Address Line 2"
          value={addressLine2}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            setAddressLine2(e.target.value);
          }}
          style={{ width: "100%" }}
        />
      </MDBox>
      <MDBox p={2}>
        {/* {addressLine3Error && (
              <MDTypography variant="caption" color="error">
                Please enter an address.
              </MDTypography>
            )} */}
        <MDInput
          type="text"
          label="Address Line 3"
          value={addressLine3}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            setAddressLine3(e.target.value);
          }}
          style={{ width: "100%" }}
        />
      </MDBox>
      <MDBox p={2}>
        {cityError && (
          <MDTypography variant="caption" color="error">
            Please enter a city.
          </MDTypography>
        )}
        <MDInput
          type="text"
          label="City"
          value={city}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            setCity(e.target.value);
          }}
          style={{ width: "100%" }}
        />
      </MDBox>
      <MDBox p={2}>
        {stateError && (
          <MDTypography variant="caption" color="error">
            Please enter a State/Province.
          </MDTypography>
        )}
        <MDInput
          type="text"
          label="State/Province"
          value={state}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            setState(e.target.value);
          }}
          style={{ width: "100%" }}
        />
      </MDBox>
      <MDBox p={2}>
        {zipError && (
          <MDTypography variant="caption" color="error">
            Please enter a postal code.
          </MDTypography>
        )}
        <MDInput
          type="text"
          label="Postal Code"
          value={zip}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            setZip(e.target.value);
          }}
          style={{ width: "100%" }}
        />
      </MDBox>
      <MDBox px={3} pt={1}>
        {cartError && (
          <MDTypography variant="caption" color="error">
            Your cart is empty!
          </MDTypography>
        )}
      </MDBox>
      <Divider />
      {shippingSaved ? (
        <MDBox p={3}>
          <MDButton
            color="success"
            onClick={() => {
              setPageState(dispatch, "Review");
            }}
            fullWidth
          >
            Review Order
          </MDButton>
        </MDBox>
      ) : (
        <MDBox p={3}>
          <MDButton
            variant="outlined"
            color="info"
            onClick={() => {
              checkShipping({
                attn,
                email,
                phone,
                clinic,
                addressLine1,
                addressLine2,
                addressLine3,
                city,
                state,
                zip,
                country,
              });
            }}
            fullWidth
          >
            Save Shipping Address To Order
          </MDButton>
        </MDBox>
      )}
      {editOrder && (
        <MDBox px={3} py={1}>
          <MDButton
            color="dark"
            onClick={() => {
              setPageState(dispatch, "Review");
            }}
            fullWidth
          >
            return to order review
          </MDButton>
        </MDBox>
      )}
    </MDBox>
  );
}

export default CheckoutAddress;
