const sendmailApi =
  "https://q1bgx99bv9.execute-api.us-east-1.amazonaws.com/default/storefront_sendmail_orderflow";

const sendEmail = async (el) => {
  const body = JSON.stringify({
    senderName: el.senderName,
    senderEmail: el.senderEmail,
    senderOrderNumb: el.senderOrderNumb,
    senderStudy: el.senderStudy,
    senderRoute: el.senderRoute,
    senderPM: el.senderPM,
  });

  const requestOptions = {
    method: "post",
    body,
  };

  fetch(sendmailApi, requestOptions)
    .then((response) => {
      if (!response.ok) throw new Error("Error in fetch");
      return response.json();
    })
    .then((response) => {
      console.log(response);
      console.log("Sent to Lambda successfully!");
    })
    .catch((error) => {
      console.log(error);
      console.log("Email didn't work!");
    });
};

export default sendEmail;
