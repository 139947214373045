/* eslint-disable no-restricted-syntax */
/* eslint-disable object-shorthand */

// react imports
import { useState } from "react";

// @mui material components
import { Divider, Radio } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// notistack imports
import { useSnackbar } from "notistack";

// Context imports
import { useMaterialUIController, setPageState } from "context";

// Firebase imports
import { doc, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../../../../../../Firebase";

function AddAddress() {
  const [, dispatch] = useMaterialUIController();

  const auth = getAuth();
  const user = auth.currentUser;

  const { enqueueSnackbar } = useSnackbar();

  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [isInternational, setIsInternational] = useState("United States");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [attn, setAttn] = useState("");
  const [nickName, setNickName] = useState("");
  const [attnError, setAttnError] = useState(false);
  const [nickNameError, setNickNameError] = useState(false);
  const [clinic, setClinic] = useState("");
  const [clinicError, setClinicError] = useState(false);
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine1Error, setAddressLine1Error] = useState(false);
  const [addressLine2, setAddressLine2] = useState("");
  // const [addressLine2Error, setAddressLine2Error] = useState(false);
  const [addressLine3, setAddressLine3] = useState("");
  // const [addressLine3Error, setAddressLine3Error] = useState(false);
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState(false);
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState(false);
  const [zip, setZip] = useState("");
  const [zipError, setZipError] = useState(false);

  const saveAddress = async () => {
    if (country === undefined) {
      return;
    }

    if (nickName === "") {
      setNickNameError(true);
    }

    if (attn === "") {
      setAttnError(true);
    }

    if (email === "") {
      setEmailError(true);
    }

    if (phone === "") {
      setPhoneError(true);
    }

    if (clinic === "") {
      setClinicError(true);
    }

    if (addressLine1 === "") {
      setAddressLine1Error(true);
    }

    if (city === "") {
      setCityError(true);
    }

    if (state === "") {
      setStateError(true);
    }

    if (zip === "") {
      setZipError(true);
    }

    if (country === "") {
      setCountryError(true);
    }

    if (
      nickName.length !== 0 &&
      attn.length !== 0 &&
      email.length !== 0 &&
      phone.length !== 0 &&
      clinic.length !== 0 &&
      addressLine1.length !== 0 &&
      city.length !== 0 &&
      state.length !== 0 &&
      zip.length !== 0 &&
      country.length !== 0
    ) {
      const shippingAddress = {
        nickName: nickName,
        email: email,
        phone: phone,
        attn: attn,
        country: country,
        clinic: clinic,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        addressLine3: addressLine3,
        city: city,
        state: state,
        zip: zip,
      };

      const userAddressRef = doc(db, "USERS", user.uid, "ADDRESSES", nickName);

      await setDoc(userAddressRef, shippingAddress);

      const variant = "success";
      enqueueSnackbar(`${nickName} has been added to you Address Book!`, {
        variant,
        autoHideDuration: 2000,
      });
      setPageState(dispatch, "AddressBook");
    } else {
      console.log("fill in that info!");
    }
  };

  return (
    <MDBox p={2}>
      <MDBox p={2}>
        {nickNameError && (
          <MDTypography variant="caption" color="error">
            Please enter a nick name for this address.
          </MDTypography>
        )}
        <MDInput
          type="text"
          label="Nick Name"
          value={nickName}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            setNickName(e.target.value);
          }}
          style={{ width: "100%" }}
          required
        />
      </MDBox>
      <MDBox p={2}>
        {attnError && (
          <MDTypography variant="caption" color="error">
            Please enter an ATTN to.
          </MDTypography>
        )}
        <MDInput
          type="text"
          label="Attention to"
          value={attn}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            setAttn(e.target.value);
          }}
          style={{ width: "100%" }}
          required
        />
      </MDBox>
      <MDBox p={2}>
        {emailError && (
          <MDTypography variant="caption" color="error">
            Please enter an email address.
          </MDTypography>
        )}
        <MDInput
          type="text"
          label="Email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          style={{ width: "100%" }}
          required
        />
      </MDBox>
      <MDBox p={2}>
        {phoneError && (
          <MDTypography variant="caption" color="error">
            Please enter a phone number.
          </MDTypography>
        )}
        <MDInput
          type="text"
          label="Phone #"
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
          style={{ width: "100%" }}
          required
        />
      </MDBox>
      <MDBox p={2}>
        <MDTypography variant="caption">Select Country:</MDTypography>
        <Radio
          checked={country === "United States"}
          onChange={(e) => {
            setCountry(e.target.value);
            setCountryError(false);
            setIsInternational(false);
          }}
          value="United States"
          name="radio-buttons"
          inputProps={{ "aria-label": "United States" }}
        />
        <MDTypography variant="caption">United States</MDTypography>
        <Radio
          checked={country !== "United States"}
          onChange={() => {
            setCountry("");
            setIsInternational(true);
          }}
          value="International"
          name="radio-buttons"
          inputProps={{ "aria-label": "International" }}
        />
        <MDTypography variant="caption">International</MDTypography>
      </MDBox>
      <MDBox p={2}>
        {countryError && (
          <MDTypography variant="caption" color="error">
            Please enter a Country.
          </MDTypography>
        )}
        {isInternational && (
          <MDInput
            type="text"
            label="Country Name"
            value={country}
            inputProps={{ maxLength: 35 }}
            onChange={(e) => {
              setCountry(e.target.value);
            }}
            style={{ width: "100%" }}
            required
          />
        )}
      </MDBox>
      <MDBox p={2}>
        {clinicError && (
          <MDTypography variant="caption" color="error">
            Please enter a clinic name.
          </MDTypography>
        )}
        <MDInput
          type="text"
          label="Clinic/Company Name"
          value={clinic}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            setClinic(e.target.value);
          }}
          style={{ width: "100%" }}
          required
        />
      </MDBox>
      <MDBox p={2}>
        {addressLine1Error && (
          <MDTypography variant="caption" color="error">
            Please enter an address.
          </MDTypography>
        )}
        <MDInput
          type="text"
          label="Address Line 1"
          value={addressLine1}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            setAddressLine1(e.target.value);
          }}
          style={{ width: "100%" }}
          required
        />
      </MDBox>
      <MDBox p={2}>
        {/* {addressLine2Error && (
              <MDTypography variant="caption" color="error">
                Please enter an address.
              </MDTypography>
            )} */}
        <MDInput
          type="text"
          label="Address Line 2"
          value={addressLine2}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            setAddressLine2(e.target.value);
          }}
          style={{ width: "100%" }}
        />
      </MDBox>
      <MDBox p={2}>
        {/* {addressLine3Error && (
              <MDTypography variant="caption" color="error">
                Please enter an address.
              </MDTypography>
            )} */}
        <MDInput
          type="text"
          label="Address Line 3"
          value={addressLine3}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            setAddressLine3(e.target.value);
          }}
          style={{ width: "100%" }}
        />
      </MDBox>
      <MDBox p={2}>
        {cityError && (
          <MDTypography variant="caption" color="error">
            Please enter a city.
          </MDTypography>
        )}
        <MDInput
          type="text"
          label="City"
          value={city}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            setCity(e.target.value);
          }}
          style={{ width: "100%" }}
          required
        />
      </MDBox>
      <MDBox p={2}>
        {stateError && (
          <MDTypography variant="caption" color="error">
            Please enter a State/Province.
          </MDTypography>
        )}
        <MDInput
          type="text"
          label="State/Province"
          value={state}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            setState(e.target.value);
          }}
          style={{ width: "100%" }}
          required
        />
      </MDBox>
      <MDBox p={2}>
        {zipError && (
          <MDTypography variant="caption" color="error">
            Please enter a postal code.
          </MDTypography>
        )}
        <MDInput
          type="text"
          label="Postal Code"
          value={zip}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            setZip(e.target.value);
          }}
          style={{ width: "100%" }}
          required
        />
      </MDBox>
      <Divider />
      <MDBox p={2}>
        <MDButton
          variant="contained"
          color="info"
          sx={{ mb: "1rem" }}
          onClick={() => {
            saveAddress();
          }}
          fullWidth
        >
          Save Address To Address Book
        </MDButton>
      </MDBox>
    </MDBox>
  );
}

export default AddAddress;
