/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/function-component-definition */

// react imports
import { useState, useEffect } from "react";

// firebase imports
import { getDocs, collection, query, where, orderBy } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";

// notistack imports
import { useSnackbar } from "notistack";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setOrderPage,
  setOrderPageEmpty,
  setChosenAddress,
  setFinalAddress,
  setCart,
  setEmptyCart,
  setLocalCustomization,
  setEditOrder,
  setActiveNavLink,
  setPageState,
} from "context";

// Firebase imports
import { db, auth } from "../../../../../../../Firebase";

export default function data() {
  const [rowArray, setRowArray] = useState([]);
  const [user] = useAuthState(auth);
  const [controller, dispatch] = useMaterialUIController();
  const { userStores } = controller;
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const avatars = (members) =>
      members.map(([image, key, title]) => (
        <Tooltip key={key} title={title} placeholder="bottom">
          <MDAvatar
            src={image}
            alt="name"
            size="md"
            variant="square"
            sx={{
              border: ({ borders: { borderWidth }, palette: { white } }) =>
                `${borderWidth[2]} solid ${white.main}`,
              cursor: "pointer",
              position: "relative",

              "&:not(:first-of-type)": {
                ml: { xs: 0, sm: -1.25 },
              },

              "&:hover, &:focus": {
                zIndex: "10",
              },
            }}
          />
        </Tooltip>
      ));
    const itemList = (items) =>
      items.map(([key, title, quantity]) => (
        <div key={key}>
          {title}: {quantity}
        </div>
      ));

    const setNewDocument = async () => {
      if (user) {
        const newArray = [];

        userStores.forEach(async (study) => {
          const orderDocs = collection(db, `${study}_ORDERS`);

          const q = query(
            orderDocs,
            where("orderFor", "==", `${user.email}`),
            orderBy("date_created", "desc")
          );
          const newDoc = await getDocs(q);
          const parsedDocs = newDoc.docs.map((el) => ({ ...el.data(), id: el.id }));

          for (const element of parsedDocs) {
            console.log(element);
            const itemArray = element.items.map((x) => [x.image, x.newID, x.title]);
            const itemListArray = element.items.map((x) => [x.title, x.title, x.cartQuantity]);
            const elementArray = {
              study: element.storeData.title,
              companies: `Order #: ${element.orderId}`,
              view: (
                <MDButton
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    setOrderPage(dispatch, element);
                    setOrderPageEmpty(dispatch, false);
                    setPageState(dispatch, "OrderPage");
                  }}
                >
                  view order
                </MDButton>
              ),
              reorder: (
                <MDButton
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    let customization = {
                      customized: false,
                      clinicName: "",
                      line2: "",
                      line3: "",
                    };
                    if (element.customization !== undefined) {
                      customization = {
                        customized: element.customization.customized,
                        clinicName: element.customization.clinicName,
                        line2: element.customization.line2,
                        line3: element.customization.line3,
                      };
                    }
                    setLocalCustomization(dispatch, customization);
                    window.localStorage.setItem("customization", JSON.stringify(customization));
                    setChosenAddress(dispatch, {
                      nickName: element.shippingAddress.nickName,
                      attn: element.shippingAddress.attn,
                      email: element.shippingAddress.email,
                      phone: element.shippingAddress.phone,
                      country: element.shippingAddress.country,
                      addressLine1: element.shippingAddress.addressLine1,
                      addressLine2: element.shippingAddress.addressLine2,
                      addressLine3: element.shippingAddress.addressLine3,
                      city: element.shippingAddress.city,
                      zip: element.shippingAddress.zip,
                      state: element.shippingAddress.state,
                      clinic: element.shippingAddress.clinic,
                    });
                    setFinalAddress(dispatch, {
                      nickName: element.shippingAddress.nickName,
                      attn: element.shippingAddress.attn,
                      email: element.shippingAddress.email,
                      phone: element.shippingAddress.phone,
                      country: element.shippingAddress.country,
                      addressLine1: element.shippingAddress.addressLine1,
                      addressLine2: element.shippingAddress.addressLine2,
                      addressLine3: element.shippingAddress.addressLine3,
                      city: element.shippingAddress.city,
                      zip: element.shippingAddress.zip,
                      state: element.shippingAddress.state,
                      clinic: element.shippingAddress.clinic,
                    });
                    setCart(
                      dispatch,
                      element.items.map((item) => ({ ...item, study: element.storeData.title }))
                    );
                    setEmptyCart(dispatch, false);
                    setEditOrder(dispatch, true);
                    setActiveNavLink(dispatch, "checkout");
                    const variant = "success";
                    enqueueSnackbar(`Order #: ${element.orderId} has been added to checkout!`, {
                      variant,
                      autoHideDuration: 2000,
                    });
                    setPageState(dispatch, "Review");
                  }}
                >
                  order again
                </MDButton>
              ),
              created: (
                <MDTypography variant="caption" color="text" fontWeight="medium" textAlign="left">
                  {element.date_created.toDate().toLocaleDateString("en-us", {
                    weekday: "long",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </MDTypography>
              ),
              modified: (
                <MDTypography
                  variant="caption"
                  color={element.statusColor}
                  fontWeight="medium"
                  textAlign="left"
                >
                  {element.date_modified.toDate().toLocaleDateString("en-us", {
                    weekday: "long",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </MDTypography>
              ),
              members: (
                <MDBox>
                  <MDBox display="flex" py={1}>
                    {avatars(itemArray)}
                  </MDBox>
                  <MDBox display="flex" py={1}>
                    <MDTypography
                      variant="caption"
                      color="text"
                      fontWeight="medium"
                      textAlign="left"
                    >
                      {itemList(itemListArray)}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              ),
              budget: (
                <MDTypography variant="caption" color="text" fontWeight="medium" textAlign="left">
                  Attn: {element.shippingAddress.attn}
                  <br />
                  {element.shippingAddress.addressLine1}
                  <br />
                  {element.shippingAddress.addressLine2.length === 0 ? (
                    ""
                  ) : (
                    <>
                      {element.shippingAddress.addressline2} <br />
                    </>
                  )}
                  {element.shippingAddress.addressLine3.length === 0 ? (
                    ""
                  ) : (
                    <>
                      {element.shippingAddress.addressline3} <br />
                    </>
                  )}
                  {element.shippingAddress.city}, {element.shippingAddress.state}
                  <br />
                  {element.shippingAddress.zip}
                  <br />
                  {element.shippingAddress.country}
                </MDTypography>
              ),
              completion: (
                <MDBox width="8rem">
                  <MDBadge
                    badgeContent={element.status}
                    color={element.statusColor}
                    size="lg"
                    container
                  />
                </MDBox>
              ),
            };
            newArray.push(elementArray);
          }
        });
        setRowArray(newArray);
      }
    };
    setNewDocument();
    setIsLoading(false);
  }, [userStores]);

  return {
    columns: [
      { Header: "study", accessor: "study", width: "10%", align: "left" },
      { Header: "order #", accessor: "companies", width: "10%", align: "left" },
      { Header: "status", accessor: "completion", width: "10%", align: "left" },
      { Header: "view", accessor: "view", width: "10%", align: "left" },
      { Header: "reorder", accessor: "reorder", width: "10%", align: "left" },
      { Header: "created", accessor: "created", width: "10%", align: "left" },
      { Header: "last modified", accessor: "modified", width: "10%", align: "left" },
      { Header: "items", accessor: "members", width: "20%", align: "left" },
      { Header: "shipping", accessor: "budget", align: "left" },
    ],

    rows: rowArray,

    loading: isLoading,
  };
}
