/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/function-component-definition */

// react imports
import { useState, useEffect } from "react";

// Material UI imports
import { Divider } from "@mui/material";

// notistack imports
import { useSnackbar } from "notistack";

// @mui material components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController, setChosenAddress, setPageState } from "context";

// Firebase imports
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { db } from "../../../../../../../Firebase";

export default function data() {
  const [, dispatch] = useMaterialUIController();

  const auth = getAuth();
  const userInfo = auth.currentUser;
  const [user] = useAuthState(auth);

  const [rowArray, setRowArray] = useState([]);
  const [preDelete, setPreDelete] = useState([]);

  useEffect(() => {
    console.log(preDelete);
  }, [preDelete]);

  useEffect(() => {
    console.log("USER >>>", userInfo);
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  const deleteShippingAddress = async (el) => {
    const addressRef = doc(db, "USERS", userInfo.uid, "ADDRESSES", el);
    await deleteDoc(addressRef);
    const variant = "warning";
    enqueueSnackbar(`${el} has been deleted from your Address Book.`, {
      variant,
      autoHideDuration: 2000,
    });
    setPreDelete([...preDelete, el]);
    // setPageState(dispatch, "AddressBook");
  };

  const setShippingAddress = (el) => {
    setChosenAddress(dispatch, el);
    window.localStorage.setItem("chosenAddress", JSON.stringify(el));
    const variant = "success";
    enqueueSnackbar(`${el.nickName} added to Checkout!`, {
      variant,
      autoHideDuration: 2000,
    });
    setPageState(dispatch, "Shipping");
  };

  const setNewDocument = async () => {
    if (user) {
      const userForAddressBook = userInfo.uid;

      const colRef = await getDocs(collection(db, "USERS", userForAddressBook, "ADDRESSES"));
      const addressArray = colRef.docs.map((el) => ({ ...el.data(), id: el.id }));

      const newArray = [];

      console.log(addressArray);
      for (const element of addressArray) {
        const elementArray = {
          address: (
            <MDBox sx={{ opacity: preDelete.includes(element.id) ? 0.5 : 1 }}>
              <MDTypography variant="caption">
                <strong>{element.nickName}</strong>
                <br />
                <strong>Email: </strong>
                {element.email}
                <br />
                <strong>Phone: </strong>
                {element.phone}
                <br />
                <Divider sx={{ my: ".2rem" }} />
                <strong>Attn: </strong>
                {element.attn}
                <br />
                <strong>Address: </strong>
                <br />
                {element.clinic}
                <br />
                {element.addressLine1}
                <br />
                {element.addressLine2.length !== 0 && (
                  <>
                    {element.addressLine2}
                    <br />
                  </>
                )}
                {element.addressLine3.length !== 0 && (
                  <>
                    {element.addressLine3}
                    <br />
                  </>
                )}
                {element.city}, {element.state}
                <br />
                {element.zip}
                <br />
                {element.country}
                <br />
              </MDTypography>
              <MDBox py={1}>
                <MDButton
                  variant="outlined"
                  color="info"
                  size="small"
                  onClick={() => {
                    setShippingAddress(element);
                  }}
                  sx={{ mt: ".5rem" }}
                >
                  add address to shipping
                </MDButton>
              </MDBox>
              <MDBox py={1}>
                <MDButton
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    deleteShippingAddress(element.id);
                  }}
                  sx={{ mt: ".5rem" }}
                >
                  delete shipping address
                </MDButton>
              </MDBox>
            </MDBox>
          ),
        };
        newArray.push(elementArray);
      }
      setRowArray(newArray);
    }
  };

  useEffect(() => {
    setNewDocument();
  }, []);

  useEffect(() => {
    setNewDocument();
  }, [preDelete]);

  return {
    columns: [{ Header: "", accessor: "address", width: "100%", align: "left" }],

    rows: rowArray,
  };
}
