/* eslint-disable no-restricted-syntax */

// react imports
import { createContext, useContext, useReducer, useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// Material Dashboard 2 React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "DISPLAY_NAME": {
      return { ...state, displayName: action.value };
    }
    case "CART": {
      return { ...state, cart: action.value };
    }
    case "CART_STUDIES": {
      return { ...state, cartStudies: action.value };
    }
    case "EMPTY_CART": {
      return { ...state, emptyCart: action.value };
    }
    case "ORDERS": {
      return { ...state, orders: action.value };
    }
    case "ORDER_PAGE": {
      return { ...state, orderpage: action.value };
    }
    case "ORDER_PAGE_EMPTY": {
      return { ...state, orderpageEmpty: action.value };
    }
    case "ADDRESS_BOOK": {
      return { ...state, addressBook: action.value };
    }
    case "COUNTRY_INDEX": {
      return { ...state, countryIndex: action.value };
    }
    case "CHOSEN_ADDRESS": {
      return { ...state, chosenAddress: action.value };
    }
    case "FINAL_ADDRESS": {
      return { ...state, finalAddress: action.value };
    }
    case "CUSTOMIZATION_OPTIONS": {
      return { ...state, customizationOptions: action.value };
    }
    case "LOCAL_CUSTOMIZATION": {
      return { ...state, localCustomization: action.value };
    }
    case "ACTIVE_NAV_LINK": {
      return { ...state, activeNavLink: action.value };
    }
    case "CUSTOMIZATION_PAGE": {
      return { ...state, customizationPage: action.value };
    }
    case "SHIPPING_PAGE": {
      return { ...state, shippingPage: action.value };
    }
    case "ORDER_REVIEW_PAGE": {
      return { ...state, orderReviewPage: action.value };
    }
    case "USER_STORES": {
      return { ...state, userStores: action.value };
    }
    case "CURRENT_STORE": {
      return { ...state, currentStore: action.value };
    }
    case "STORE": {
      return { ...state, store: action.value };
    }
    case "EDIT_ORDER": {
      return { ...state, editOrder: action.value };
    }
    case "CHECKOUT_EDIT": {
      return { ...state, checkoutEdit: action.value };
    }
    case "REMEMBER_ME": {
      return { ...state, rememberMe: action.value };
    }
    case "PAGE_STATE": {
      return { ...state, pageState: action.value };
    }
    case "PM": {
      return { ...state, pm: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function setInitialLocalStorageCartState() {
  const localStorageCart = window.localStorage.getItem("cart");
  if (localStorageCart === null || localStorageCart === undefined) {
    return [];
  }
  return JSON.parse(localStorageCart);
}

function setInitialLocalStorageCartStudiesState() {
  const localStorageCart = window.localStorage.getItem("cartStudies");
  if (localStorageCart === null || localStorageCart === undefined) {
    return [];
  }
  return JSON.parse(localStorageCart);
}

function checkCartIsEmpty() {
  const localStorageCart = window.localStorage.getItem("cart");
  if (localStorageCart === null || localStorageCart === undefined) {
    return true;
  }
  if (JSON.parse(localStorageCart).length > 0) {
    return false;
  }
  return true;
}

function setInitialLocalStorageAddressBookState() {
  const localStorageCart = window.localStorage.getItem("addressBook");
  if (localStorageCart === null || localStorageCart === undefined) {
    return [];
  }
  return JSON.parse(localStorageCart);
}

function setInitialChosenAddressState() {
  const localStorageCart = window.localStorage.getItem("chosenAddress");
  if (localStorageCart === null || localStorageCart === undefined) {
    return {
      nickName: "",
      attn: "",
      clinic: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: "",
      state: "",
      zip: "",
      country: "United States",
    };
  }
  return JSON.parse(localStorageCart);
}

function setInitialLocalCustomization() {
  const localStorageCart = window.localStorage.getItem("customization");
  if (localStorageCart === null || localStorageCart === undefined) {
    return { customized: false, clinicName: "", line2: "", line3: "" };
  }
  return JSON.parse(localStorageCart);
}

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "loading",
    darkMode: false,
    displayName: "",
    cart: setInitialLocalStorageCartState(),
    cartStudies: setInitialLocalStorageCartStudiesState(),
    emptyCart: checkCartIsEmpty(),
    orders: [],
    orderpage: {
      city: "",
      status: "",
      customization: {
        customized: false,
        clinicName: "",
        line2: "",
        line3: "",
      },
      addressLine1: "",
      attn: "",
      statusTimeline: [
        {
          icon: "border_color",
          status: "",
          statusColor: "secondary",
          date: {
            seconds: 1655996889,
            nanoseconds: 784000000,
          },
          last: false,
          title: "",
        },
      ],
      addressLine3: "",
      items: [
        {
          language: "",
          title: "",
          quantityOptions: [],
          image: "",
          willCustomize: false,
          customizationItems: [],
          cartQuantity: "",
        },
      ],
      orderId: "",
      statusColor: "",
      date_modified: {
        seconds: 1655996889,
        nanoseconds: 784000000,
      },
      zip: "",
      clinic: "",
      addressLine2: "",
      date_created: {
        seconds: 1655996889,
        nanoseconds: 784000000,
      },
      state: "",
      country: "",
      id: "",
    },
    orderpageEmpty: true,
    addressBook: setInitialLocalStorageAddressBookState(),
    countryIndex: 235,
    chosenAddress: setInitialChosenAddressState(),
    finalAddress: {
      attn: "",
      clinic: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: "",
      state: "",
      zip: "",
      country: "United States",
    },
    activeNavLink: "/",
    customizationPage: [],
    customizationOptions: ["Clinic Name", "Email", "Phone#"],
    localCustomization: setInitialLocalCustomization(),
    shippingPage: [],
    orderReviewPage: [],
    userStores: [],
    currentStore: "",
    store: [],
    editOrder: false,
    checkoutEdit: false,
    rememberMe: false,
    pageState: "",
    pm: "matt@qdsolutions.com",
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const setDisplayName = (dispatch, value) => dispatch({ type: "DISPLAY_NAME", value });
const setCart = (dispatch, value) => dispatch({ type: "CART", value });
const setCartStudies = (dispatch, value) => dispatch({ type: "CART_STUDIES", value });
const setEmptyCart = (dispatch, value) => dispatch({ type: "EMPTY_CART", value });
const setOrderCart = (dispatch, value) => dispatch({ type: "ORDERS", value });
const setOrderPage = (dispatch, value) => dispatch({ type: "ORDER_PAGE", value });
const setOrderPageEmpty = (dispatch, value) => dispatch({ type: "ORDER_PAGE_EMPTY", value });
const setAddressBook = (dispatch, value) => dispatch({ type: "ADDRESS_BOOK", value });
const setCountryIndex = (dispatch, value) => dispatch({ type: "COUNTRY_INDEX", value });
const setChosenAddress = (dispatch, value) => dispatch({ type: "CHOSEN_ADDRESS", value });
const setFinalAddress = (dispatch, value) => dispatch({ type: "FINAL_ADDRESS", value });
const setCustomizationOptions = (dispatch, value) =>
  dispatch({ type: "CUSTOMIZATION_OPTIONS", value });
const setLocalCustomization = (dispatch, value) => dispatch({ type: "LOCAL_CUSTOMIZATION", value });
const setActiveNavLink = (dispatch, value) => dispatch({ type: "ACTIVE_NAV_LINK", value });
const setCustomizationPage = (dispatch, value) => dispatch({ type: "CUSTOMIZATION_PAGE", value });
const setShippingPage = (dispatch, value) => dispatch({ type: "SHIPPING_PAGE", value });
const setOrderReviewPage = (dispatch, value) => dispatch({ type: "ORDER_REVIEW_PAGE", value });
const setUserStores = (dispatch, value) => dispatch({ type: "USER_STORES", value });
const setCurrentStore = (dispatch, value) => dispatch({ type: "CURRENT_STORE", value });
const setStore = (dispatch, value) => dispatch({ type: "STORE", value });
const setEditOrder = (dispatch, value) => dispatch({ type: "EDIT_ORDER", value });
const setCheckoutEdit = (dispatch, value) => dispatch({ type: "CHECKOUT_EDIT", value });
const setRememberMe = (dispatch, value) => dispatch({ type: "REMEMBER_ME", value });
const setPageState = (dispatch, value) => dispatch({ type: "PAGE_STATE", value });
const setPM = (dispatch, value) => dispatch({ type: "PM", value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  setDisplayName,
  setCart,
  setCartStudies,
  setEmptyCart,
  setOrderCart,
  setOrderPage,
  setOrderPageEmpty,
  setAddressBook,
  setCountryIndex,
  setChosenAddress,
  setFinalAddress,
  setCustomizationOptions,
  setLocalCustomization,
  setActiveNavLink,
  setCustomizationPage,
  setShippingPage,
  setOrderReviewPage,
  setUserStores,
  setCurrentStore,
  setStore,
  setEditOrder,
  setCheckoutEdit,
  setRememberMe,
  setPageState,
  setPM,
};
