/* eslint-disable no-unused-expressions */

// react imports
import { useEffect, useCallback, useRef } from "react";

// @mui material components
import { Grid, Card, Divider } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// react canvas confetti
import ReactCanvasConfetti from "react-canvas-confetti";

// Material Dashboard 2 React context
import { useMaterialUIController, setPageState } from "context";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
};

function ThankYou() {
  const [controller, dispatch] = useMaterialUIController();
  const { userStores } = controller;

  // fireworks
  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);
  // end of fireworks

  useEffect(() => {
    fire();
  }, []);

  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={10} md={6}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Order Placed
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <MDBox p={2}>
                <MDBox p={2}>
                  <MDTypography variant="h2">Thank you for your order!</MDTypography>
                  <MDTypography variant="body2">
                    You probably want to know… what happens next? Now that you’ve submitted your
                    order, it will first be reviewed by the Storefront team to confirm the validity
                    and parameters of the request. It’s then provided to the study sponsor for their
                    review. If there are any questions, we’ll follow up with you by email. Once your
                    order is approved, it’ll be batched with similar tool orders for production.
                    When your order is shipped, you’ll receive a notification email from us with
                    tracking info. So for now, your work here is done!
                  </MDTypography>
                  <Divider />
                  <Grid container spacing={6}>
                    <Grid item xs={6}>
                      <MDButton
                        variant="outlined"
                        color="info"
                        onClick={() => {
                          setPageState(dispatch, "Orders");
                        }}
                        fullWidth
                      >
                        View Orders
                      </MDButton>
                    </Grid>
                    <Grid item xs={6}>
                      <MDButton
                        variant="contained"
                        color="info"
                        onClick={() => {
                          setPageState(dispatch, userStores[0]);
                        }}
                        fullWidth
                      >
                        Start New Order
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
    </MDBox>
  );
}

export default ThankYou;
