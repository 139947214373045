// react imports
import { useState } from "react";

// @mui material components
import { Card, Icon } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

// Firebase imports
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { logInWithEmailAndPassword } from "../../../Firebase";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const auth = getAuth();

  const [sent, setSent] = useState(false);

  const [reset, setReset] = useState(false);

  const setEmailValue = (input) => {
    setEmail(input.target.value);
  };

  const setPasswordValue = (input) => {
    setPassword(input.target.value);
  };

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      logInWithEmailAndPassword(email, password);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      {reset ? (
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            py={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography
              display="block"
              variant="button"
              color="white"
              my={1}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setReset(false);
              }}
            >
              <Icon>arrow_back_ios</Icon> Return to Sign-In
            </MDTypography>
            <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
              Reset Password
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" m={1}>
              {sent ? "Message sent!" : "You will receive an e-mail to reset your password"}
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              {sent ? (
                <MDTypography display="block" variant="button" my={1}>
                  Message sent! You will recieve a reset password email shortly
                </MDTypography>
              ) : (
                <>
                  <MDBox mb={4}>
                    <MDInput
                      type="email"
                      label="Email"
                      variant="standard"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mt={6} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={() => {
                        sendPasswordResetEmail(auth, email)
                          .then(() => {
                            setSent(true);
                          })
                          .catch((error) => {
                            const errorCode = error.code;
                            const errorMessage = error.message;
                            console.log(errorCode, errorMessage);
                          });
                      }}
                      fullWidth
                    >
                      reset
                    </MDButton>
                  </MDBox>
                </>
              )}
            </MDBox>
          </MDBox>
        </Card>
      ) : (
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Log In
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  onChange={setEmailValue}
                  onKeyPress={handleKeypress}
                  fullWidth
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  onChange={setPasswordValue}
                  onKeyPress={handleKeypress}
                  fullWidth
                />
              </MDBox>
              {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    logInWithEmailAndPassword(email, password);
                  }}
                  fullWidth
                >
                  sign in
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Forgot your password?{" "}
                  <MDTypography
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setReset(true);
                    }}
                  >
                    Reset password
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      )}
    </BasicLayout>
  );
}

export default SignIn;
