// @mui material components
import { Grid, Card } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React components
import CustomizationForm from "layouts/dashboard/components/Customization/components/CustomizationForm";

function Customization() {
  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={10} md={6}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Customize Your Items
              </MDTypography>
            </MDBox>
            <MDBox px={2} pt={3}>
              <MDTypography variant="caption" paragraph>
                Customizations allow a site to list specific contact information that potential
                patients can use to contact them. The name of the clinic must be listed on the top
                line, the second and third line can include an address, a contact name, a phone
                number, or an email address.
                <br />
                Each line has a 35 character limit.
              </MDTypography>
            </MDBox>
            <CustomizationForm />
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Customization;
