// @mui material components
import { Grid, Card } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// checkout components
import CheckoutCart from "layouts/dashboard/components/Checkout/components/CheckoutCart";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

function Checkout() {
  const [controller] = useMaterialUIController();
  const { cart } = controller;

  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={10} md={6}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Your Order
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <CheckoutCart header="your cart" profiles={cart} shadow={false} />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Checkout;
